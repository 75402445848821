import '../styles/main.css';
import '../styles/investor.css';
import '../scripts/investor';
import React, { useState } from 'react';

    const Investor = () => {
        const [formData, setFormData] = useState({
            fullName: '',
            email: '',
            phone: '',
            linkedin: '',
            country: '',
            businessName: '',
            businessWebsite: '',
            industry: [],
            industryOther: '',
            stage: '',
            description: '',
            valueProposition: '',
            fundingAmount: '',
            useOfFunds: [],
            useOfFundsOther: '',
            fundingStatus: '',
            roiTimeline: '',
            motivation: '',
            challenges: '',
            contactPreference: [],
            additionalInfo: '',
            terms: false,
        });
    
        const handleChange = (e) => {
            const { name, value, type, checked } = e.target;
            if (type === 'checkbox') {
                if (name === 'terms') {
                    setFormData({ ...formData, [name]: checked });
                } else if (formData[name].includes(value)) {
                    setFormData({ ...formData, [name]: formData[name].filter((item) => item !== value) });
                } else {
                    setFormData({ ...formData, [name]: [...formData[name], value] });
                }
            } else {
                setFormData({ ...formData, [name]: value });
            }
        };
    
        const handleSubmit = (e) => {
            e.preventDefault();
            console.log('Form Data Submitted:', formData);
            // Here you can add the logic to send the form data to your backend
        };
    
        return (
            <div className='main-div-investor'>
            <form onSubmit={handleSubmit}>
                <h1 className='main-heading'>Investment Seeker Application Form</h1>
                <p className='main-text'>Welcome to the Harvester! We connect innovative business ideas with visionary investors. To ensure a seamless and productive experience, please fill out the following application form. Your information will help us match you with relevant investors.</p>
    
                <fieldset>
                    <legend>Personal Information</legend>
                    <label>
                        Full Name:<br/>
                        <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
                    </label><br />
    
                    <label>
                        Email Address:<br/>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </label><br />
    
                    <label>
                        Phone Number:<br/>
                        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                    </label><br />
    
                    <label>
                        LinkedIn Profile:<br/>
                        <input type="url" name="linkedin" value={formData.linkedin} onChange={handleChange} />
                    </label><br /><br/>
    
                    <label>
                        Country of Residence:
                        <select name="country" value={formData.country} onChange={handleChange} required>
                            <option value="" disabled>Select your country</option>
                            <option value="US">United States</option>
                            <option value="UK">United Kingdom</option>
                            <option value="CA">Canada</option>
                            <option value="AU">Australia</option>
                            {/* Add other countries as needed */}
                        </select>
                    </label>
                </fieldset>
                <br/>
                <fieldset>
                    <legend>Business Information</legend>
                    <label>
                        Business Name:<br/>
                        <input type="text" name="businessName" value={formData.businessName} onChange={handleChange} required />
                    </label><br />
    
                    <label>
                        Business Website:<br/>
                        <input type="url" name="businessWebsite" value={formData.businessWebsite} onChange={handleChange} />
                    </label><br />
    
                    <label>Business Industry: (Select all that apply)</label><br />
                    {['Technology', 'Healthcare', 'Finance', 'Real Estate', 'Consumer Goods', 'Energy', 'Education'].map((industry) => (
                        <label key={industry}>
                            <input type="checkbox" name="industry" value={industry} onChange={handleChange} />
                            {industry}
                        </label>
                    ))}
                    <label>
                        Other:
                        <input type="text" name="industryOther" value={formData.industryOther} onChange={handleChange} />
                    </label><br />
    
                    <label>Business Stage:</label><br />
                    {['Idea/Concept', 'Startup (Product Development)', 'Early Stage (Initial Revenue)', 'Growth Stage (Scaling Operations)', 'Established (Profitable)'].map((stage) => (
                        <label key={stage}>
                            <input type="radio" name="stage" value={stage} checked={formData.stage === stage} onChange={handleChange} required />
                            {stage}
                        </label>
                    ))}<br />
    
                    <label>
                        Description of Business:
                        <textarea name="description" value={formData.description} onChange={handleChange} required />
                    </label><br />
    
                    <label>
                        Unique Value Proposition:
                        <textarea name="valueProposition" value={formData.valueProposition} onChange={handleChange} required />
                    </label>
                </fieldset>
    
                <fieldset>
                    <legend>Funding Information</legend>
                    <label>Amount of Funding Sought:</label><br />
                    {['Under $50,000', '$50,000 - $200,000', '$200,000 - $500,000', '$500,000 - $1,000,000', 'Over $1,000,000'].map((amount) => (
                        <label key={amount}>
                            <input type="radio" name="fundingAmount" value={amount} checked={formData.fundingAmount === amount} onChange={handleChange} required />
                            {amount}
                        </label>
                    ))}<br />
    
                    <label>Intended Use of Funds: (Select all that apply)</label><br />
                    {['Product Development', 'Marketing and Sales', 'Hiring and Team Building', 'Research and Development', 'Operations'].map((use) => (
                        <label key={use}>
                            <input type="checkbox" name="useOfFunds" value={use} onChange={handleChange} />
                            {use}
                        </label>
                    ))}
                    <label>
                        Other:
                        <input type="text" name="useOfFundsOther" value={formData.useOfFundsOther} onChange={handleChange} />
                    </label><br />
    
                    <label>
                        Current Funding Status:
                        <textarea name="fundingStatus" value={formData.fundingStatus} onChange={handleChange} />
                    </label><br />
    
                    <label>Expected Timeline for ROI:</label><br />
                    {['Short Term (1-3 years)', 'Medium Term (3-7 years)', 'Long Term (7+ years)'].map((timeline) => (
                        <label key={timeline}>
                            <input type="radio" name="roiTimeline" value={timeline} checked={formData.roiTimeline === timeline} onChange={handleChange} required />
                            {timeline}
                        </label>
                    ))}
                </fieldset>
    
                <fieldset>
                    <legend>Additional Information</legend>
                    <label>
                        What are your primary motivations for seeking investment?
                        <textarea name="motivation" value={formData.motivation} onChange={handleChange} />
                    </label><br />
    
                    <label>
                        What are the biggest challenges your business currently faces?
                        <textarea name="challenges" value={formData.challenges} onChange={handleChange} />
                    </label><br />
    
                    <label>How do you prefer to be contacted? (Select all that apply)</label><br />
                    {['Email', 'Phone', 'LinkedIn'].map((contact) => (
                        <label key={contact}>
                            <input type="checkbox" name="contactPreference" value={contact} onChange={handleChange} />
                            {contact}
                        </label>
                    ))}<br />
    
                    <label>
                        Any other information you’d like to share with us?
                        <textarea name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} />
                    </label>
                </fieldset>
    
                <fieldset>
                    <legend>Terms and Conditions</legend>
                    <label>
                        <input type="checkbox" name="terms" checked={formData.terms} onChange={handleChange} required />
                        I agree to the terms and conditions of the Harvester
                    </label>
                </fieldset>
    
                <button type="submit">Submit</button>
            </form>
            </div>
        );
    };
    

export default Investor;
