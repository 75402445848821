import '../styles/main.css';
import '../styles/InvestorApplicationForm.css';
import React, { useState } from 'react';

const InvestorApplicationForm = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        linkedin: '',
        country: '',
        investmentFocus: [],
        investmentFocusOther: '',
        investmentStage: [],
        investmentSize: '',
        investmentHorizon: '',
        professionalBackground: '',
        investingExperience: '',
        expertiseIndustries: '',
        memberships: '',
        investmentMotivation: '',
        contactPreference: [],
        investmentCriteria: '',
        additionalInfo: '',
        terms: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            if (name === 'terms') {
                setFormData({ ...formData, [name]: checked });
            } else if (formData[name].includes(value)) {
                setFormData({ ...formData, [name]: formData[name].filter((item) => item !== value) });
            } else {
                setFormData({ ...formData, [name]: [...formData[name], value] });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data Submitted:', formData);
        // Here you can add the logic to send the form data to your backend
    };

    return (
        <form onSubmit={handleSubmit}>
            <h1>Investor Application Form</h1>
            <p>Welcome to Growing Golds We connect visionary investors with innovative business ideas. To ensure a seamless and productive experience, please fill out the following application form. Your information will help us match you with relevant opportunities.</p>

            <fieldset>
                <legend>Personal Information</legend>
                <label>
                    Full Name:
                    <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
                </label><br />

                <label>
                    Email Address:
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label><br />

                <label>
                    Phone Number:
                    <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                </label><br />

                <label>
                    LinkedIn Profile:
                    <input type="url" name="linkedin" value={formData.linkedin} onChange={handleChange} />
                </label><br />

                <label>
                    Country of Residence:
                    <select name="country" value={formData.country} onChange={handleChange} required>
                        <option value="" disabled>Select your country</option>
                        <option value="US">United States</option>
                        <option value="UK">United Kingdom</option>
                        <option value="CA">Canada</option>
                        <option value="AU">Australia</option>
                        {/* Add other countries as needed */}
                    </select>
                </label>
            </fieldset>

            <fieldset>
                <legend>Investment Preferences</legend>
                <label>Investment Focus Areas: (Select all that apply)</label><br />
                {['Technology', 'Healthcare', 'Finance', 'Real Estate', 'Consumer Goods', 'Energy', 'Education'].map((focus) => (
                    <label key={focus}>
                        <input type="checkbox" name="investmentFocus" value={focus} onChange={handleChange} />
                        {focus}
                    </label>
                ))}
                <label>
                    Other:
                    <input type="text" name="investmentFocusOther" value={formData.investmentFocusOther} onChange={handleChange} />
                </label><br />

                <label>Investment Stage Preferences: (Select all that apply)</label><br />
                {['Seed Stage', 'Early Stage', 'Growth Stage', 'Late Stage'].map((stage) => (
                    <label key={stage}>
                        <input type="checkbox" name="investmentStage" value={stage} onChange={handleChange} />
                        {stage}
                    </label>
                ))}<br />

                <label>Average Investment Size:</label><br />
                {['Under $50,000', '$50,000 - $200,000', '$200,000 - $500,000', '$500,000 - $1,000,000', 'Over $1,000,000'].map((size) => (
                    <label key={size}>
                        <input type="radio" name="investmentSize" value={size} checked={formData.investmentSize === size} onChange={handleChange} required />
                        {size}
                    </label>
                ))}<br />

                <label>Preferred Investment Horizon:</label><br />
                {['Short Term (1-3 years)', 'Medium Term (3-7 years)', 'Long Term (7+ years)'].map((horizon) => (
                    <label key={horizon}>
                        <input type="radio" name="investmentHorizon" value={horizon} checked={formData.investmentHorizon === horizon} onChange={handleChange} required />
                        {horizon}
                    </label>
                ))}
            </fieldset>

            <fieldset>
                <legend>Background and Experience</legend>
                <label>
                    Professional Background:
                    <textarea name="professionalBackground" value={formData.professionalBackground} onChange={handleChange} required />
                </label><br />

                <label>
                    Relevant Experience in Investing:
                    <textarea name="investingExperience" value={formData.investingExperience} onChange={handleChange} />
                </label><br />

                <label>
                    Industries of Expertise:
                    <textarea name="expertiseIndustries" value={formData.expertiseIndustries} onChange={handleChange} />
                </label><br />

                <label>
                    Memberships and Affiliations:
                    <textarea name="memberships" value={formData.memberships} onChange={handleChange} />
                </label>
            </fieldset>

            <fieldset>
                <legend>Additional Information</legend>
                <label>
                    What are your primary motivations for investing?
                    <textarea name="investmentMotivation" value={formData.investmentMotivation} onChange={handleChange} />
                </label><br />

                <label>How do you prefer to be contacted? (Select all that apply)</label><br />
                {['Email', 'Phone', 'LinkedIn'].map((contact) => (
                    <label key={contact}>
                        <input type="checkbox" name="contactPreference" value={contact} onChange={handleChange} />
                        {contact}
                    </label>
                ))}<br />

                <label>
                    Are there any specific criteria or conditions you look for in an investment opportunity?
                    <textarea name="investmentCriteria" value={formData.investmentCriteria} onChange={handleChange} />
                </label><br />

                <label>
                    Any other information you’d like to share with us?
                    <textarea name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} />
                </label>
            </fieldset>

            <fieldset>
                <legend>Terms and Conditions</legend>
                <label>
                    <input type="checkbox" name="terms" checked={formData.terms} onChange={handleChange} required />
                    I agree to the terms and conditions of the Harvester
                </label>
            </fieldset>

            <button type="submit">Submit</button>
        </form>
    );
};

export default InvestorApplicationForm;
